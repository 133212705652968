<template>
  <div class="fixed top-0 left-0 right-0 z-[60] flex justify-center items-center px-3 h-screen bg-opacity-30 bg-black">
    <div class="relative dom-border max-w-xl w-full modal-body bg-cream pt-16 pb-16 px-3 flex justify-center">
      <div class="text-center">
        <img
          data-testid="close-modal"
          src="/close-modal.png"
          class="absolute w-9 top-5 right-5 cursor-pointer"
          @click="$emit('close')"
        >
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-body {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}
</style>
